if (process.env.NODE_ENV === "development" && process.env.FAST_REFRESH === "true" && typeof window !== "undefined") {
  // eslint-disable-next-line @typescript-eslint/no-require-imports,import/no-extraneous-dependencies
  const runtime = require("react-refresh/runtime");
  runtime.injectIntoGlobalHook(window);
}

// some of our packages might still be "compiled" with the $RefreshSig$ helper
// even if we disabled the FAST_REFRESH env var we still need an empty implementation for them
if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
  (window as any).$RefreshSig$ = () => (type) => type;
}

export {};
