import { DialogContent, Paper } from "@mui/material";

import SimpleDialog from "../../../Components/SimpleDialog";
import MarkdownToReact from "../../../utils/storyblok/MarkdownToReact";

interface AgreementDialogProp {
  content: string | null;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string | null;
  isLoading: boolean;
}

const AgreementDialog = ({ content, isOpen, onConfirm, onCancel, title, isLoading }: AgreementDialogProp) => {
  if (isLoading) {
    return null;
  }

  return (
    <SimpleDialog
      open={isOpen}
      title={title || ""}
      confirmButtonText="Close"
      onConfirm={onConfirm}
      showCancel={false}
      onCancel={onCancel}
      dialogProps={{
        fullWidth: true,
        maxWidth: "md",
        scroll: "body",
      }}
    >
      <DialogContent>
        <Paper sx={{ mt: 2, px: 2, overflow: "scroll", maxHeight: "60vh" }} variant="outlined">
          <MarkdownToReact pProps={{ py: 2 }} typographyPropsOverrides={{ color: "inherit" }}>
            {content}
          </MarkdownToReact>
        </Paper>
      </DialogContent>
    </SimpleDialog>
  );
};

export default AgreementDialog;
